import CDM307 from '../assets/img/miniForklifts/CDM307.png';
import CDM308 from '../assets/img/miniForklifts/CDM308.png';
import CDM312 from '../assets/img/miniForklifts/CDM312.png';
import frontalForklift from '../assets/img/frontForklifts/frontForklift.png';
import CDM932N from '../assets/img/frontForklifts/CDM932N.png';
import CDM816D from '../assets/img/frontForklifts/CDM816D.png';
import CDM818D from '../assets/img/frontForklifts/CDM818D.png';
import CDM818DG from '../assets/img/frontForklifts/CDM818DG.png';
import CDM936N from '../assets/img/frontForklifts/CDM936N.png';
import LG833N from '../assets/img/frontForklifts/LG833N.png';
import CDM835 from '../assets/img/frontForklifts/CDM835.png';
import CDM853 from '../assets/img/frontForklifts/CDM853.png';
import ZL50NC from '../assets/img/frontForklifts/ZL50NC.png';
import CDM856 from '../assets/img/frontForklifts/CDM856.png';
import CDM853N from '../assets/img/frontForklifts/CDM853N.png';
import ZL50NCS from '../assets/img/frontForklifts/ZL50NCS.png';
import LG863N from '../assets/img/frontForklifts/LG863N.png';
import LG876N from '../assets/img/frontForklifts/LG876N.png';
import Shanmon from '../assets/Shanmon_card.png';
import S388H from '../assets/img/backhoeLoader/388H.png';
import S388P from '../assets/img/backhoeLoader/388T.png';


export const data = [
    {
        url: 'mini-pogruzchik',
        title: 'Мини погрузчики',
        nameLink: 'miniForklifts',
        img: CDM307,
        pages:[
            {
                url: 'mini-pogruzchik-lonking-CDM307',
                titleProduct: 'Мини погрузчик LONKING CDM307',
                nameLinkProduct: 'CDM307',
                imgProduct: CDM307,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '800кг',   // Грузоподъемность
                    TippingLoad: '1740кг',   // Нагрузка опрокидывания
                    OperatingWeight: '2800кг',   // Эксплуатационная масса
                    UnloadingHeight: '3020мм',   // Высота выгрузки (по шарниру ковша)
                    Engine: 'XINCHAI A498BT1-175 лиц.Perkins США/КНР',   // Двигатель
                    EnginePower: '36.7(49.9) кВт(л.с)',   // Мощность двигателя
                    HydraulicFlow: '74 л/мин',   // Гидравлический поток
                    TireSize: '10.0-16.5',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '83 л.',   // Объем топливного бака
                    BucketIncluded: '0.43 м3',   // Объем ковша в комплекте
                    DrivingSpeed: '0–12.6 км/ч'   // Скорость движения
                }
            },
            {
                url: 'mini-pogruzchik-lonking-CDM308',
                titleProduct: 'Мини погрузчик LONKING CDM308',
                nameLinkProduct: 'CDM308',
                imgProduct: CDM308,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '900кг',   // Грузоподъемность
                    TippingLoad: '1820кг',   // Нагрузка опрокидывания
                    OperatingWeight: '3000кг',   // Эксплуатационная масса
                    UnloadingHeight: '3020мм',   // Высота выгрузки (по шарниру ковша)
                    Engine: 'XINCHAI A498BT1 лиц.Perkins США/КНР',   // Двигатель
                    EnginePower: '36.7(49.9) кВт(л.с)',   // Мощность двигателя
                    HydraulicFlow: '74 л/мин',   // Гидравлический поток
                    TireSize: '10.0-16.5',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '83 л.',   // Объем топливного бака
                    BucketIncluded: '0.43 м3',   // Объе м ковша в комплекте
                    DrivingSpeed: '0–12.6 км/ч'   // Скорость движения
                }
            },
            {
                url: 'mini-pogruzchik-lonking-CDM312',
                titleProduct: 'Мини погрузчик LONKING CDM312',
                nameLinkProduct: 'CDM312',
                imgProduct: CDM312,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '1230кг',   // Грузоподъемность
                    TippingLoad: '2460кг',   // Нагрузка опрокидывания
                    OperatingWeight: '3700кг',   // Эксплуатационная масса
                    UnloadingHeight: '3060мм',   // Высота выгрузки (по шарниру ковша)
                    Engine: 'XINCHAI A498BZG лиц.Perkins США/КНР',   // Двигатель
                    EnginePower: '55(75) кВт(л.с.)',   // Мощность двигателя
                    HydraulicFlow: '91 л/мин',   // Гидравлический поток
                    TireSize: '12,0-16,5',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '91 л.',   // Объем топливного бака
                    BucketIncluded: '0.63 м3',   // Объе м ковша в комплекте
                    DrivingSpeed: '0–12.6 км/ч'   // Скорость движения
                }
            }
        ]
    },
    {
        url: 'frontalny-pogruzchik',
        title: 'Фронтальные погрузчики',
        nameLink: 'frontForklift',
        img: frontalForklift,
        pages:[
            {
                url: 'frontalny-pogruzchik-lonking-CDM932N',
                titleProduct: 'Фронтальный погрузчик LONKING CDM932N',
                nameLinkProduct: 'CDM932N',
                imgProduct: CDM932N,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '1500кг',   // Грузоподъемность
                    OperatingWeight: '5500кг',   // Эксплуатационная масса
                    Engine: 'Yunnei YN38GBZ1',   // Двигатель
                    EnginePower: '89 л.с.',   // Мощность двигателя
                    BucketWidth: '2100мм', // Ширина ковша
                    TotalCycleTime: '8,2c',  // Общее время цикла
                    TotalLength: '5925мм',  // Общая длина
                    TurningRadius: '6420мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '8/20 км/ч',   // Скорость движения вперед
                    BackSpeed: '8/20 км/ч',   // Скорость движения назад
                    TireSize: '16/70-20',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '90 л.',   // Объем топливного бака
                    BucketIncluded: '1.2 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-CDM816D',
                titleProduct: 'Фронтальный погрузчик LONKING CDM816D',
                nameLinkProduct: 'CDM816D',
                imgProduct: CDM816D,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '1600кг',   // Грузоподъемность
                    OperatingWeight: '5500кг',   // Эксплуатационная масса
                    Engine: 'YTO / YT4A2-24',   // Двигатель
                    EnginePower: '75 л.с.',   // Мощность двигателя
                    BucketWidth: '1950мм', // Ширина ковша
                    TotalCycleTime: '9,4c',  // Общее время цикла
                    TotalLength: '5700мм',  // Общая длина
                    TurningRadius: '5030мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '8/21 км/ч',   // Скорость движения вперед
                    BackSpeed: '8/21 км/ч',   // Скорость движения назад
                    TireSize: '16/70-20',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '110 л.',   // Объем топливного бака
                    BucketIncluded: '0.95 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-CDM818D',
                titleProduct: 'Фронтальный погрузчик LONKING CDM818D',
                nameLinkProduct: 'CDM818D',
                imgProduct: CDM818D,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '1800кг',   // Грузоподъемность
                    OperatingWeight: '5600кг',   // Эксплуатационная масса
                    Engine: 'YTO / YT4B3-24',   // Двигатель
                    EnginePower: '86 л.с.',   // Мощность двигателя
                    BucketWidth: '2200мм', // Ширина ковша
                    TotalCycleTime: '9,4c',  // Общее время цикла
                    TotalLength: '5700мм',  // Общая длина
                    TurningRadius: '5100мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '8/21 км/ч',   // Скорость движения вперед
                    BackSpeed: '8/21 км/ч',   // Скорость движения назад
                    TireSize: '16/70-20',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '110 л.',   // Объем топливного бака
                    BucketIncluded: '1.05 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-CDM818DG',
                titleProduct: 'Фронтальный погрузчик LONKING CDM818DG',
                nameLinkProduct: 'CDM818DG',
                imgProduct: CDM818DG,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '1800кг',   // Грузоподъемность
                    OperatingWeight: '5600кг',   // Эксплуатационная масса
                    Engine: 'YTO / YT4B3-24',   // Двигатель
                    EnginePower: '86 л.с.',   // Мощность двигателя
                    BucketWidth: '2010мм', // Ширина ковша
                    TotalCycleTime: '9,4c',  // Общее время цикла
                    TotalLength: '5900мм',  // Общая длина
                    TurningRadius: '5230мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '8/21 км/ч',   // Скорость движения вперед
                    BackSpeed: '8/21 км/ч',   // Скорость движения назад
                    TireSize: '17.5-25',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '110 л.',   // Объем топливного бака
                    BucketIncluded: '1.5 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-CDM936N',
                titleProduct: 'Фронтальный погрузчик Lonking CDM936N',
                nameLinkProduct: 'CDM936N',
                imgProduct: CDM936N,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '2000кг',   // Грузоподъемность
                    OperatingWeight: '6380кг',   // Эксплуатационная масса
                    Engine: 'YUNNEI YN38GBZ2',   // Двигатель
                    EnginePower: '97 л.с.',   // Мощность двигателя
                    BucketWidth: '2200мм', // Ширина ковша
                    TotalCycleTime: '9c',  // Общее время цикла
                    TotalLength: '6350мм',  // Общая длина
                    TurningRadius: '6490мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '8/20 км/ч',   // Скорость движения вперед
                    BackSpeed: '8/20 км/ч',   // Скорость движения назад
                    TireSize: '23.5-25',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '90 л.',   // Объем топливного бака
                    BucketIncluded: '1.5 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-LG833N',
                titleProduct: 'Фронтальный погрузчик LONKING LG833N',
                nameLinkProduct: 'LG833N',
                imgProduct: LG833N,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '3000кг',   // Грузоподъемность
                    OperatingWeight: '6280кг',   // Эксплуатационная масса
                    Engine: 'WEICHAI / WP6G125E22',   // Двигатель
                    EnginePower: '125 л.с.',   // Мощность двигателя
                    BucketWidth: '2500мм', // Ширина ковша
                    TotalCycleTime: '9c',  // Общее время цикла
                    TotalLength: '7450мм',  // Общая длина
                    TurningRadius: '6280мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '7/12/24/36 км/ч',   // Скорость движения вперед
                    BackSpeed: '8,5/28 км/ч',   // Скорость движения назад
                    TireSize: '23.5-25',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '200 л.',   // Объем топливного бака
                    BucketIncluded: '1.8 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-CDM835',
                titleProduct: 'Фронтальный погрузчик LONKING CDM835',
                nameLinkProduct: 'CDM835',
                imgProduct: CDM835,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '3500кг',   // Грузоподъемность
                    OperatingWeight: '12300кг',   // Эксплуатационная масса
                    Engine: 'WEICHAI / WP6G140E22',   // Двигатель
                    EnginePower: '143 л.с.',   // Мощность двигателя
                    BucketWidth: '2500мм', // Ширина ковша
                    TotalCycleTime: '10.5c',  // Общее время цикла
                    TotalLength: '7770мм',  // Общая длина
                    TurningRadius: '6280мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '6,5/12/32 км/ч',   // Скорость движения вперед
                    BackSpeed: '6,8/12,5/33,5 км/ч',   // Скорость движения назад
                    TireSize: '18-25',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '200 л.',   // Объем топливного бака
                    BucketIncluded: '2.3 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-CDM853',
                titleProduct: 'Фронтальный погрузчик LONKING CDM853',
                nameLinkProduct: 'CDM853',
                imgProduct: CDM853,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '5000кг',   // Грузоподъемность
                    OperatingWeight: '16800кг',   // Эксплуатационная масса
                    Engine: 'WEICHAI / WD10G220E21',   // Двигатель
                    EnginePower: '220 л.с.',   // Мощность двигателя
                    BucketWidth: '3010мм', // Ширина ковша
                    TotalCycleTime: '11.5c',  // Общее время цикла
                    TotalLength: '7870мм',  // Общая длина
                    TurningRadius: '6805мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '11,5/36 км/ч',   // Скорость движения вперед
                    BackSpeed: '16 км/ч',   // Скорость движения назад
                    TireSize: '23.5-25',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '280 л.',   // Объем топливного бака
                    BucketIncluded: '3 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-ZL50NC',
                titleProduct: 'Фронтальный погрузчик LONKING ZL50NC',
                nameLinkProduct: 'ZL50NC',
                imgProduct: ZL50NC,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '5000кг',   // Грузоподъемность
                    OperatingWeight: '17700кг',   // Эксплуатационная масса
                    Engine: 'WEICHAI / WD10G220E21',   // Двигатель
                    EnginePower: '220 л.с.',   // Мощность двигателя
                    BucketWidth: '2835мм', // Ширина ковша
                    TotalCycleTime: '11c',  // Общее время цикла
                    TotalLength: '8565мм',  // Общая длина
                    TurningRadius: '7450мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '11,5/36 км/ч',   // Скорость движения вперед
                    BackSpeed: '16 км/ч',   // Скорость движения назад
                    TireSize: '23.5-25',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '285 л.',   // Объем топливного бака
                    BucketIncluded: '3 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-CDM856',
                titleProduct: 'Фронтальный погрузчик LONKING CDM856',
                nameLinkProduct: 'CDM856',
                imgProduct: CDM856,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '5000кг',   // Грузоподъемность
                    OperatingWeight: '17500кг',   // Эксплуатационная масса
                    Engine: 'WEICHAI / WD10G220E21',   // Двигатель
                    EnginePower: '220 л.с.',   // Мощность двигателя
                    BucketWidth: '3010мм', // Ширина ковша
                    TotalCycleTime: '11c',  // Общее время цикла
                    TotalLength: '8320мм',  // Общая длина
                    TurningRadius: '7240мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '11,5/36 км/ч',   // Скорость движения вперед
                    BackSpeed: '16 км/ч',   // Скорость движения назад
                    TireSize: '16/70-24',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '285 л.',   // Объем топливного бака
                    BucketIncluded: '3 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-CDM853N',
                titleProduct: 'Фронтальный погрузчик LONKING CDM853N',
                nameLinkProduct: 'CDM853N',
                imgProduct: CDM853N,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '5000кг',   // Грузоподъемность
                    OperatingWeight: '16440кг',   // Эксплуатационная масса
                    Engine: 'WEICHAI / WD10G220E21',   // Двигатель
                    EnginePower: '220 л.с.',   // Мощность двигателя
                    BucketWidth: '2650мм', // Ширина ковша
                    TotalCycleTime: '11,5c',  // Общее время цикла
                    TotalLength: '7860мм',  // Общая длина
                    TurningRadius: '6680мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '11,5/36 км/ч',   // Скорость движения вперед
                    BackSpeed: '16 км/ч',   // Скорость движения назад
                    TireSize: '16/70-24',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '280 л.',   // Объем топливного бака
                    BucketIncluded: '3 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-ZL50NCS',
                titleProduct: 'Фронтальный погрузчик LONKING ZL50NCS',
                nameLinkProduct: 'ZL50NCS',
                imgProduct: ZL50NCS,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '5600кг',   // Грузоподъемность
                    OperatingWeight: '19000кг',   // Эксплуатационная масса
                    Engine: 'WEICHAI / WD10G220E341',   // Двигатель
                    EnginePower: '220 л.с.',   // Мощность двигателя
                    BucketWidth: '3100мм', // Ширина ковша
                    TotalCycleTime: '11c',  // Общее время цикла
                    TotalLength: '8570мм',  // Общая длина
                    TurningRadius: '7100мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '11,5/36 км/ч',   // Скорость движения вперед
                    BackSpeed: '16 км/ч',   // Скорость движения назад
                    TireSize: '16/70-24',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '285 л.',   // Объем топливного бака
                    BucketIncluded: '3,6 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-LG863N',
                titleProduct: 'Фронтальный погрузчик LONKING LG863N',
                nameLinkProduct: 'LG863N',
                imgProduct: LG863N,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '6500кг',   // Грузоподъемность
                    OperatingWeight: '23300кг',   // Эксплуатационная масса
                    Engine: 'WEICHAI / WP10G240E341',   // Двигатель
                    EnginePower: '242 л.с.',   // Мощность двигателя
                    BucketWidth: '3200мм', // Ширина ковша
                    TotalCycleTime: '10.8c',  // Общее время цикла
                    TotalLength: '8760мм',  // Общая длина
                    TurningRadius: '7688мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '14/38 км/ч',   // Скорость движения вперед
                    BackSpeed: '19,5 км/ч',   // Скорость движения назад
                    TireSize: '17.5-25',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '330 л.',   // Объем топливного бака
                    BucketIncluded: '4,2 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'frontalny-pogruzchik-lonking-LG876N',
                titleProduct: 'Фронтальный погрузчик LONKING LG876N',
                nameLinkProduct: 'LG876N',
                imgProduct: LG876N,
                description: {
                    manufacturer: 'LONKING',   // Производитель	
                    loadСapacity: '7000кг',   // Грузоподъемность
                    OperatingWeight: '23500кг',   // Эксплуатационная масса
                    Engine: 'WEICHAI / WP10G270E341',   // Двигатель
                    EnginePower: '270 л.с.',   // Мощность двигателя
                    BucketWidth: '3300мм', // Ширина ковша
                    TotalCycleTime: '10.5c',  // Общее время цикла
                    TotalLength: '8886мм',  // Общая длина
                    TurningRadius: '7763мм',  // Радиус поворота по внешней кромке ковша
                    ForwardSpeed: '6,2/12,2/22,8/36 км/ч',   // Скорость движения вперед
                    BackSpeed: '7,5/12,7/22,8 км/ч',   // Скорость движения назад
                    TireSize: '26,5-25',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '350 л.',   // Объем топливного бака
                    BucketIncluded: '5,2 куб.м.'   // Объем ковша в комплекте
                }
            }
        ]
    },
    {
        url: 'ekskavatory-pogruzchik',
        title: 'Экскаваторы-погрузчики',
        nameLink: 'Shanmon',
        img: Shanmon,
        pages:[
            {
                url: 'pogruzchik-ekskavator-shanmon-388H',
                titleProduct: 'Погрузчик-экскаватор SHANMON 388H',
                nameLinkProduct: '388H',
                imgProduct: S388H,
                description: {
                    manufacturer: 'SHANMON',   // Производитель	
                    loadСapacity: '2500кг',   // Грузоподъемность
                    OperatingWeight: '10800кг',   // Эксплуатационная масса
                    Engine: 'YC4A105Z-T20 (рядный, прямой впрыск)',   // Двигатель
                    EnginePower: '102 л.с.',   // Мощность двигателя
                    TotalLength: '6450мм',  // Общая длина
                    DrivingSpeed: '32 км/ч',   // Скорость движения
                    TireSize: '16,9-28',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '125 л.',   // Объем топливного бака
                    BucketIncluded: '1,2 куб.м./0.3 куб.м.'   // Объем ковша в комплекте
                }
            },
            {
                url: 'pogruzchik-ekskavator-shanmon-388P',
                titleProduct: 'Погрузчик-экскаватор SHANMON 388P',
                nameLinkProduct: '388P',
                imgProduct: S388P,
                description: {
                    manufacturer: 'SHANMON',   // Производитель	
                    loadСapacity: '2500кг',   // Грузоподъемность
                    OperatingWeight: '8200кг',   // Эксплуатационная масса
                    Engine: 'Weichai WP4G95E221',   // Двигатель
                    EnginePower: '94,5 л.с.',   // Мощность двигателя
                    TotalLength: '6230мм',  // Общая длина
                    DrivingSpeed: '32 км/ч',   // Скорость движения
                    TireSize: '16,9-28',   // Размер шин
                    Control: 'Джойстиковое (сервопривод)',   // Управление
                    FuelCapacity: '125 л.',   // Объем топливного бака
                    BucketIncluded: '1 куб.м./0.3 куб.м.'   // Объем ковша в комплекте
                }
            }
        ]
    },
    // {
    //     title: '3',
    //     nameLink: 'three',
    //     img: '',
    //     pages:[
    //         {
    //             titleProduct: 'погрузчики #1',
    //             nameLinkProduct: 'forklift1',
    //             imgProduct: ''
    //         },
    //         {
    //             titleProduct: 'погрузчики #2',
    //             nameLinkProduct: 'forklift2',
    //             imgProduct: ''
    //         },
    //         {
    //             titleProduct: 'погрузчики #3',
    //             nameLinkProduct: 'forklift3',
    //             imgProduct: ''
    //         },
    //         {
    //             titleProduct: 'погрузчики #4',
    //             nameLinkProduct: 'forklift4',
    //             imgProduct: ''
    //         },
    //         {
    //             titleProduct: 'погрузчики #5',
    //             nameLinkProduct: 'forklift5',
    //             imgProduct: ''
    //         }
    //     ]
    // }
]