import React from 'react';
import classes from './MainAdvantages.module.css';
import Background from '../../../assets/backgroung_adventages.png';

export default function MainAdvantages() {
  const texts = [
    'Корпорация входит в число мировых лидеров по производству и экспорту фронтальных, вилочных и мини-погрузчиков, её продукция пользуется заслуженной популярностью.',
    'Новые погрузчики Лонкинг соответствуют мировым стандартам качества - они оснащены мощными и экономичными двигателями, узлами и агрегатами собственного или импортного производства, собираются на высокотехнологичных производственных линиях.',
    'В продаже представлен широкий модельный ряд техники с различными характеристиками и возможностями, что позволяет удовлетворить любые запросы покупателей.',
    'Все китайские колесные погрузчики разработаны с учетом условий российского климата и могут эффективно работать на самых сложных производственных участках. Также они просты в обслуживании, редко ломаются и отличаются высокой ремонтопригодностью.',
    'В наличии оригинальные запчасти и пункт сервисного обслуживания, специализирующийся на ремонте и ТО спецтехники Лонкинг. Это позволяет сократить время вынужденного простоя в несколько раз.',
    'Производитель поддерживает доступные цены на все виды спецтехники. В условиях экономического кризиса конкурентноспособная продукция китайских машиностроителей стала еще более востребованной на совеременном рынке.'
  ];
    return (
    <div className={classes.main_advantages__wrapper}>
      <span className={classes.main_advantages__title}>Преимущества техники Лонкинг</span>
      <div className={classes.main_advantages__img_and_text}>
        <img src={Background} alt='Фон для погрузчиков' className={classes.main_advantages__img}/>
        <ul className={classes.main_advantages__text_wrapper}>
          {texts.map((text, id) => {
            return <li className={classes.main_advantages__text} key={id}>{text}</li>
          })}
        </ul>
      </div>
    </div>
    );
}